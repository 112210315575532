/*
    Flaticon icon font: Flaticon
    Creation date: 09/07/2020 14:05
    */

@font-face {
  font-family: "Flaticon";
  src: url("/assets/icons/flaticons/font/Flaticon.eot");
  src: url("/assets/icons/flaticons/font/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("/assets/icons/flaticons/font/Flaticon.woff2") format("woff2"),
    url("/assets/icons/flaticons/font/Flaticon.woff") format("woff"),
    url("/assets/icons/flaticons/font/Flaticon.ttf") format("truetype"),
    url("/assets/icons/flaticons/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("/assets/icons/flaticons/font/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-magnifying-search-lenses-tool:before {
  content: "\f100";
}
.flaticon-facebook:before {
  content: "\f101";
}
.flaticon-instagram:before {
  content: "\f102";
}
.flaticon-youtube:before {
  content: "\f103";
}
.flaticon-time:before {
  content: "\f104";
}
.flaticon-calendar:before {
  content: "\f105";
}
.flaticon-right-arrow:before {
  content: "\f106";
}
.flaticon-left-arrow:before {
  content: "\f107";
}
.flaticon-whatsapp:before {
  content: "\f108";
}

$font-Flaticon-magnifying-search-lenses-tool: "\f100";
$font-Flaticon-facebook: "\f101";
$font-Flaticon-instagram: "\f102";
$font-Flaticon-youtube: "\f103";
$font-Flaticon-time: "\f104";
$font-Flaticon-calendar: "\f105";
$font-Flaticon-right-arrow: "\f106";
$font-Flaticon-left-arrow: "\f107";
$font-Flaticon-whatsapp: "\f108";

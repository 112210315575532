/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : May 10, 2019, 5:30:09 PM
    Author     : jbmengue
*/

$body-bg: #fff;
$body-color: #111111;
$font-size-base: 1rem;
$font-weight-base: 400;
$font-weight-bolder: 700;
$headings-font-weight: 700;
$headings-line-height: 1;

$font-family-base: "Open Sans", sans-serif;
$font-family-primary: "DM Sans", sans-serif;
$font-family-secondary: "Montserrat", sans-serif;

$default: #111111;
$primary: #1f1643;
$primary-light: #4b36a2;
$secondary: #ebc512;
$gray-light: #f5f5f5;
$gray-400: #e8e8e8;
$gray-600: #888888;
$gray-800: #474848;
$gray-900: #333333;
$dark: #000;

$theme-colors: (
  "primary-light": $primary-light,
  "gray-light": $gray-light
);

$headings-font-family: $font-family-primary;
$headings-color: $default;
$headings-margin-bottom: 3.125rem;
$h1-font-size: 1.875rem;
$h2-font-size: 1.75rem;

$link-color: $default;
$link-decoration: "none";

$btn-disabled-opacity: 1;
$btn-color-default: #fff;

$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-nav-link-padding-x: 0.6rem;
$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$nav-link-padding-y: 0;

$btn-border-width: 1px;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$display1-weight: $font-weight-bold;
$display2-weight: $font-weight-bold;
$display3-weight: $font-weight-bold;
$display4-weight: $font-weight-bold;

$modal-header-padding: 3rem 0 1rem 0;
$modal-header-border-width: 0;
$zindex-modal-backdrop: 999999999;
$zindex-modal: 99999999999;
$modal-title-line-height: 1;

$font-size-xx-small: 0.625rem; /*10px*/
$font-size-x-small: 0.6875rem; /*12px*/
$font-size-small: 0.875rem; /*14px*/
$font-size-normalsize: 1rem; /*16px*/
$font-size-large: 1.125rem; /*18px*/
$font-size-x-large: 1.25rem; /*20px*/
$font-size-xx-large: 1.375rem; /*22px*/
$font-size-xxx-large: 1.5625rem; /*25px*/
$font-size-big: 1.875rem; /*30px*/
$font-size-x-big: 2.1875rem; /*35px*/
$font-size-xx-big: 2.5rem; /*40px*/

$form-group-margin-bottom: 1rem;
$input-bg: #fff;
$input-color: $gray-800;
$input-placeholder-color:  $gray-800;
$input-border-color: $gray-400;
$input-font-size: $font-size-normalsize;
$input-font-weight: $font-weight-regular;
$input-focus-border-color: $gray-900;
$input-height: 48px;
$input-border-radius: 5px;
$input-padding-x: 1rem;
$input-focus-box-shadow: 0;

$custom-file-height-inner: 100%;
$custom-file-padding-y: 0.6rem;
$custom-file-text: (
    pt: "Selecionar",
);

$tooltip-bg: $primary;
$tooltip-font-size: $font-size-small;

$pagination-active-bg: $default;

$spacer: 1.8rem;

// Required
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

// Optional
@import "./node_modules/bootstrap/scss/reboot";
@import "./node_modules/bootstrap/scss/utilities";
@import "./node_modules/bootstrap/scss/nav";
@import "./node_modules/bootstrap/scss/navbar";
@import "./node_modules/bootstrap/scss/pagination";
@import "./node_modules/bootstrap/scss/transitions";
@import "./node_modules/bootstrap/scss/images";
@import "./node_modules/bootstrap/scss/type";
@import "./node_modules/bootstrap/scss/buttons";
@import "./node_modules/bootstrap/scss/close";
@import "./node_modules/bootstrap/scss/modal";
@import "./node_modules/bootstrap/scss/forms";
@import "./node_modules/bootstrap/scss/custom-forms";
@import "./node_modules/bootstrap/scss/grid";
@import "./node_modules/bootstrap/scss/tooltip";
@import "./node_modules/bootstrap/scss/alert";

$fa-font-path: "/assets/icons/fontawesome/webfonts";
@import "./assets/icons/fontawesome/scss/fontawesome";
@import "./assets/icons/fontawesome/scss/solid";
@import "./assets/icons/fontawesome/scss/regular";
@import "./assets/icons/fontawesome/scss/brands";

@import "./node_modules/swiper/swiper";

.fa,
.fas {
    &::before {
        font-weight: 900 !important;
    }
}
.far {
    &::before {
        font-weight: 400 !important;
    }
}
.fab {
    &::before {
        font-weight: 400 !important;
    }
}

@import "./assets/icons/flaticons/font/_flaticon";
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
    margin-right: 5px;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:focus,
*:hover {
    outline: none !important;
}
img {
    max-width: 100%;
}
p {
    min-height: 18px;
}

@mixin transition {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    transition: all 300ms linear;
}
a,
button,
*:before,
*:after,
label,
.transition {
    @include transition;
}

@mixin valign {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.valign {
    @media (min-width: 768px) {
        @include valign;
    }
}

@mixin radius-round {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}
@mixin radius-small {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

@mixin background-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.container-fluid {
    max-width: 1120px;
}
.container-fluid.wide {
    max-width: 1400px !important;
}
.img-full {
    width: 100%;
    height: auto;
    display: block;
}
.stroke-style-text {
    position: relative;
    font-family: $font-family-primary;
    font-weight: $font-weight-bold;
    font-size: $font-size-x-small;
    text-transform: uppercase;

    span {
        position: relative;
        z-index: 99;
    }

    &::after {
        content: "";
        position: absolute;
        width: 110%;
        height: 65%;
        bottom: -12%;
        left: -5%;
        background: $secondary;
    }
}
.title-line{
    position: relative;
    text-transform: uppercase;

    span{
        position: relative;
        display: inline-block;
        background-color: $white;
        padding-right: 20px;
    }

    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $gray-400;
        top: 50%;
        margin-top: -.5px;
    }
}

/**
*   BEGIN: Fonts
*/
.font-family-base {
    font-family: $font-family-base;
}
.font-family-primary {
    font-family: $font-family-primary;
}
.font-family-secondary {
    font-family: $font-family-secondary;
}

.regular {
    font-weight: $font-weight-regular;
}
.medium {
    font-weight: $font-weight-medium;
}
.bold {
    font-weight: $font-weight-bold;
}
/*
*   END: Fonts
*/

/*
*   BEGIN: Colors
*/
.color-default {
    color: $default;
}
.color-primary {
    color: $primary;
}
.color-secondary {
    color: $secondary;
}
.color-gray-light {
    color: $gray-light;
}
.color-gray-400 {
    color: $gray-400;
}
.color-gray-600 {
    color: $gray-600;
}
.color-gray-800 {
    color: $gray-800;
}
.color-gray-900 {
    color: $gray-900;
}
.color-dark {
    color: $dark;
}
/*
*   END: Colors
*/

/**
*   BEGIN: Fonts Size
*/
.xx-small {
    font-size: $font-size-xx-small;
}
.x-small {
    font-size: $font-size-x-small;
}
.small {
    font-size: $font-size-small;
}
.normalsize {
    font-size: $font-size-normalsize;
}
.large {
    font-size: $font-size-large;
}
.x-large {
    font-size: $font-size-x-large;
}
.xx-large {
    font-size: $font-size-xx-large;
}
.xxx-large {
    font-size: $font-size-xxx-large;
}
.big {
    font-size: $font-size-big;
}
.x-big {
    font-size: $font-size-x-big;
}
.xx-big {
    font-size: $font-size-xx-big;
}
/**
*   END: Fonts Size
*/

/*
*   BEGIN: Buttons
*/

.btn i:before {
    @extend .small;
}
.btn:hover {
    text-decoration: none;
}
.btn-style-1 {
    position: relative;
    @include button-variant($primary-light, $primary-light, $primary, $primary-light);
    @include button-size(20px, 35px, $font-size-small, $font-size-small, 5px);
    text-decoration: none;
    font-family: $font-family-primary;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    &:hover {
        color: $white;
    }
}

.btn-style-2 {
    position: relative;
    @include button-variant($secondary, $secondary, $white, $secondary);
    @include button-size(12px, 50px, $font-size-normalsize, $font-size-normalsize, 50px);
    text-decoration: none;
    font-family: $font-family-primary;
    font-weight: $font-weight-bold;
    color: $white;
    text-transform: uppercase;

    &:hover {
        color: $secondary;
    }
}
.btn-style-3 {
    position: relative;
    @include button-variant($secondary, $secondary, $white, $secondary);
    @include button-size(15px, 15px, $font-size-x-large, $font-size-x-large, 0);
    text-decoration: none;
    text-transform: uppercase;
    font-family: $font-family-secondary;
    color: $white;
    padding-bottom: 10px;

    &:hover {
        color: $secondary;
    }
}

.btn-style-4 {
    position: relative;
    @include button-variant($white, $white, $primary-light, $white);
    @include button-size(20px, 35px, $font-size-small, $font-size-small, 5px);
    text-decoration: none;
    font-family: $font-family-primary;
    color: $default;
    text-transform: uppercase;
    &:hover {
        color: $white;
        border-color: $primary-light;
    }
}

/* BEGIN: Whatsapp */
.btn-whatsapp{
    position: fixed;
    font-family: $font-family-secondary;
    font-weight: $font-weight-medium;
    font-size: $font-size-large;
    background-color: #25d366;
    color: $white;
    line-height: 26px;
    padding: 15px;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    z-index: 99999;
    right: 2.5%;
    bottom: 2.5%;
    text-decoration: none;
    border: 2px solid #25d366;

    i{
        &::before{
            font-size: $font-size-big;
            margin-right: 15px;
            float: left;
        }
    }

    &:hover{
        text-decoration: none;
        background-color: $white;
        color: #25d366;
    }
}
/* END: Whatsapp */

/*
*   END: Buttons
*/

/*
*   BEGIN: Validation
*/
label.error {
    font-size: 10px;
    color: #f06363;
    right: 15px;
    top: 8px;
    float: right;
    margin: -48px 8px;
}
textarea > label.error {
    top: 0;
}

.msg-registration {
    text-align: center;
    background: #ccc;
    display: table;
    margin: 0 auto;
    padding: 25px 30px 0;
}
.msg-registration.default {
    background: none;
}
.msg-registration p {
    font-size: 0.75rem;
}
.msg-registration.success,
.msg-registration.success h5 {
    background-color: $primary-light;
    color: $white;
    margin-bottom: 1rem;
}
.msg-registration.error,
.msg-registration.error h5 {
    background-color: $secondary;
    color: $white;
    margin-bottom: 1rem;
}
.msg-registration.success {
    border: 1px solid $primary;
}
.msg-registration.error {
    border: 1px solid $secondary;
}
.bs-callout-success h4 {
    color: $primary;
}
.bs-callout-danger h4 {
    color: $secondary;
}
/*
*   END: Validation
*/

/*
*   BEGIN: Social Network Icons
*/
.social-networks-items {
    a {
        position: relative;
        display: inline-block;
        text-decoration: none;

        i:before {
            position: relative;
            font-size: $font-size-xx-big;
            margin: 0;
            z-index: 9;
            margin-right: 8px;
        }

        &:hover {
            text-decoration: none;
        }
    }
}
/*
*   END: Social Network Icons
*/

/*
*   BEGIN: Modal
*/
.modal{
    .close{
        display: block;
        background-color: $secondary;
        width: 40px;
        height: 40px;
        padding: 6px 12px;
        position: absolute;
        right: -8px;
        top: -8px;
        @media(min-width: 450px){
            right: -18px;
            top: -20px;
        }
        opacity: 1;
        z-index: 9999999;
        @include radius-round;
        @include transition;

        &:hover{
            width: 50px;
            height: 50px;
            opacity: 1 !important;
            padding: 11px 16px;
            right: -23px;
            top: -25px;
        }
    }
}
#modal-search{
    text-align: center;
}
/*
*   END: Modal
*/

/*
*   BEGIN: Slideshow
*/
.swiper-container {
    .swiper-wrapper{
        position: absolute;
    }
    .swiper-slide {
        &.load-image {
            @include background-cover;
        }
    }

    .swiper-button-nav {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        z-index: 99;
        cursor: pointer;

        i {
            display: block;
            position: relative;
            width: 40px;
            height: 40px;
            text-align: center;
            padding: 7px;

            &::before {
                position: relative;
                color: $white;
                margin: 0;
                z-index: 99;
                font-size: $font-size-small;
            }
            &::after {
                content: "";
                position: absolute;
                background-color: rgba($color: $black, $alpha: 0.4);
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                @include radius-round;
            }
        }

        &.swiper-button-disabled {
            opacity: 0.2;
        }

        &.swiper-button-prev {
            left: 2.5%;
        }
        &.swiper-button-next {
            right: 2.5%;
        }
    }

    .swiper-pagination {
        bottom: 0px;
        position: absolute;
        z-index: 99;
        text-align: center;
        padding: 20px 0;
        width: 100%;

        &.swiper-pagination-bullets {
            .swiper-pagination-bullet {
                display: inline-block;
                width: 12px;
                height: 12px;
                border: 2px solid $primary-light;
                @include radius-round;
                @include transition;
                margin: 0 4px;
                cursor: pointer;

                &.swiper-pagination-bullet-active {
                    background-color: $primary-light;
                }
            }
        }
    }
}
/*
*   END: Slideshow
*/

/*
*   BEGIN: Header
*/
header{
    position: relative;
    z-index: 9999;
}
.bar-top {
    background-color: $primary;

    .logo-top {
        display: block;
        max-width: 140px;
        @media (max-width: 768px) {
            max-width: 115px;
        }
    }

    .btn {
        text-transform: none;
        border-radius: 20px;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        padding: 0.375rem 0.75rem;
    }
    .btn-search {
        width: 28px;
        height: 28px;
        padding: 6px;
        text-align: center;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;

        &:hover{
            background-color: $white;
            color: $primary-light;
        }

        &:focus{
            color: $white;
            background-color: $primary-light;
            border-color: $primary-light;
            box-shadow: none;
        }

        i {
            &::before {
                margin-right: 0;
            }
        }
    }
}
.btn-search-top-simulator{
    &:hover{
        background-color: $white;
        color: $primary-light;
    }
    &:focus{
        color: $white;
        background-color: $primary-light;
        border-color: $primary-light;
        box-shadow: none;
    }
}
/*
*   END: Header
*/

/*
*   BEGIN: Navbar
*/
.navbar {
    .navbar-nav {
        .nav-item {
            a {
                position: relative;
                color: $white;
                font-family: $font-family-secondary;
                text-transform: uppercase;
                font-size: $font-size-small;
                text-decoration: none;
                font-weight: 600;
                padding: 22px 0;
                text-align: center;

                @media (min-width: 768px) {
                    margin: 0 1.3rem;
                    padding: 0;
                }

                &:after{
                    content: '';
                    display: block;
                    height: 2px;
                    width: 0;
                    bottom: -3px;
                    left: 50%;
                    background-color: $secondary;
                    position: absolute;
                    @include transition;
                }

                &:hover{
                    &:after{
                        width: 100%;
                        left: 0;
                    }
                }
            }

            &:first-child{
                a{
                    margin-left: 0;
                }
            }
            &:last-child{
                a{
                    margin-right: 0;
                }
            }

            &:hover{
                & > ul{
                    display: block;
                }
            }

            ul{
                list-style: none;
                padding: 40px 0 0 0;
                margin-top: -5px;
                z-index: 9999;
                display: none;
                margin-left: -2px;
                @media (min-width: 768px){
                    position: absolute;
                }

                .nav-item {
                    a{
                        display: block;
                        background-color: $primary-light;
                        text-transform: none;
                        padding: 10px 20px;
                        margin: -1px 0 0 0;
                        @media (min-width: 768px){
                            text-align: left;
                        }

                        &:hover,
                        &.active{
                            background-color: $primary;
                        }

                        &::after{
                            display: none;
                        }
                    }

                    &:first-child{
                        a{
                            padding-top: 20px;
                            border-radius: 8px 8px 0px 0px;
                            -moz-border-radius: 8px 8px 0px 0px;
                            -webkit-border-radius: 8px 8px 0px 0px;
                        }
                    }
                    &:last-child{
                        a{
                            border-radius: 0px 0px 8px 8px;
                            -moz-border-radius: 0px 0px 8px 8px;
                            -webkit-border-radius: 0px 0px 8px 8px;
                            padding-bottom: 20px;
                        }
                    }
                }
            }
            
        }
    }
}
.icon-bar {
    width: 45px;
    height: 3px;
    background-color: #fff;
    display: block;
    margin-bottom: 11px;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
}
/*
*   END: Navbar
*/

/*
*   BEGIN: Aside
*/
aside,
.aside-categories {
    .aside-item{
        margin-bottom: 3rem;
    }
    h2{
        font-size: $font-size-large;
        line-height: 29px;
    }

    .categories{
        list-style: none;
        padding: 0;

        li{
            margin-bottom: 10px;

            h3{
                margin-bottom: 0;
                line-height: unset;

                a{
                    display: block;
                    position: relative;
                    font-weight: $font-weight-bold;
                    font-size: $font-size-x-small;
                    text-transform: uppercase;
                    background-color: $primary-light;
                    border: 2px solid $primary-light;
                    text-decoration: none;
                    color: $white;
                    padding: 24px 50px 24px 25px;
                    @include radius-small;
    
                    span{
                        display: block;
                        position: absolute;
                        right: 20px;
                        top: 50%;
                        margin-top: -15px;
                        width: 30px;
                        height: 30px;
                        background-color: $gray-light;
                        color: $dark;
                        text-align: center;
                        font-size: $font-size-x-small;
                        padding: 7px 0;
                        @include radius-round;
                    }
    
                    &:hover,&.active{
                        text-decoration: none;
                        background-color: $white;
                        color: $primary-light;
    
                        span{
                            background-color: $primary-light;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .banner{
        display: block;
        @include radius-small;
        overflow: hidden;
        text-decoration: none;

        span:not(.load-image){
            display: block;
            text-align: center;
            color: $white;
            text-transform: uppercase;
            font-size: $font-size-small;
            padding: 18px 0;
            font-weight: $font-weight-bold;
            position: relative;
        }

        &:hover{
            span.load-image{
                img{
                    transform: scale(1.1);
                    @include transition;
                }
            }
        }
    }

    .list-posts{
        list-style: none;
        padding: 0;
        margin: 0;

        li{
            margin-bottom: .9rem;

            a{
                text-decoration: none;
                @include radius-small;
                padding: .5rem 0;

                .load-image{
                    position: relative;
                    @include radius-round;
                    @include background-cover;

                    span{
                        display: block;
                        @include radius-round;
                        background-color: $dark;
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        top: 0px;
                        left: 0px;
                        color: #fff;
                        text-align: center;
                        font-size: $font-size-x-small;
                        font-weight: $font-weight-bold;
                        padding: 2px 0;
                    }
                }

                h3{
                    font-size: $font-size-normalsize;
                    margin: 5px 0;
                }

                .additional-information{
                    span{
                        font-size: $font-size-x-small;
                        margin: 0;
                    }
                }

                &:hover{
                    background-color: $primary-light;
                    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.20);
                    -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.20);
                    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.20);
                    transform: scale(1.02);

                    & *{
                        color: $white;
                    }
                }
            }
        }
    }

    .aside-newsletter{
        text-align: center;
        @include radius-small;
        @media(min-width: 768px){
            text-align: left;
        }

        #frm-newsletter{
            .form-control{
                border: 0;
                height: 48px;
                @include radius-small;
            }
            .msg-registration{
                margin-top: 10px;
                background: none;
                border: 0;
                padding: 0;

                h5{
                    font-size: $font-size-large;
                    color: $primary-light;
                    background: none;
                    border: 0;
                }
            }
        }
    }

    .social-networks{
        list-style: none;
        padding: 0;
        margin: 0;

        li{
            display: inline-block;
            margin-right: 5px;

            a{
                display: block;
                width: 50px;
                height: 50px;
                @include radius-round;
                @include background-cover;
                background-size: 20px;
                background-color: $gray-400;
            }
        }
    }
    
}
/*
*   END: Aside
*/

/*
*   BEGIN: Footer
*/
footer {
    min-height: 300px;

    .simulate-your-plan {
        background-color: $secondary;
        color: $white;
        text-align: center;
        font-size: $font-size-x-large;
        font-family: $font-family-primary;
        font-weight: $font-weight-bold;
    }
    .footer-content {
        position: relative;
        background: rgb(49, 36, 107); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(49, 36, 107, 1) 0%, rgba(41, 30, 90, 1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            rgba(49, 36, 107, 1) 0%,
            rgba(41, 30, 90, 1) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            rgba(49, 36, 107, 1) 0%,
            rgba(41, 30, 90, 1) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#31246b', endColorstr='#291e5a',GradientType=0 ); /* IE6-9 */
    }

    .container-fluid {
        position: relative;
        z-index: 9999;
    }

    .ft-logo {
        display: block;
        max-width: 180px;
    }

    h2 {
        font-size: $font-size-x-large;
        color: $white;
        margin-bottom: 20px;
    }

    p {
        color: $white;
        font-size: $font-size-small;
        line-height: 24px;

        i:before {
            font-size: $font-size-x-small;
            margin-right: 8px;
        }
    }

    .bcb-license {
        font-size: $font-size-small;
        color: $white;
        img {
            max-height: 22px;
            margin-top: -4px;
        }

        &::after {
            content: "";
            display: block;
            position: relative;
            width: 100%;
            height: 1px;
            background-color: $white;
            bottom: 0;
            left: 0;
            margin-top: 0.4rem;
            margin-bottom: 4rem;
        }
    }

    .copyright,
    .copyright a {
        color: $white;
        font-size: $font-size-small;
    }

    .list-social-network {
        li {
            a {
                position: relative;
                background-color: $primary-light;
                display: block;
                height: 30px;
                width: 30px;
                padding: 4px 0;
                border-radius: 3px;
                -moz-border-radius: 3px;
                -webkit-border-radius: 3px;
                text-align: center;
                color: $white;

                &:hover {
                    background-color: $white;
                    color: $primary;
                }

                &::before {
                    font-size: $font-size-normalsize;
                }
            }

            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
}
/*
*   END: Footer
*/

/*
*   BEGIN: Home
*/
.main-slider {
    min-height: 550px;
    @media(min-width: 650px){
        min-height: 400px;
    }
    
    .slider-content {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .slider-content-info {
            background: $white;
            border-radius: 12px;
            -moz-border-radius: 12px;
            -webkit-border-radius: 12px;
            padding: 35px 60px;
            text-align: center;
            max-width: 600px;

            h1 {
                font-family: $font-family-primary;
                font-weight: $font-weight-bold;
                margin: 20px 0 25px 0;
            }

            .btn-link {
                position: relative;
                font-family: $font-family-primary;
                font-size: $font-size-normalsize;
                text-transform: uppercase;
                text-decoration: none;
                padding: 0;

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: $primary-light;
                    bottom: -2px;
                    left: 0;
                    @include transition;
                }

                &:hover {
                    color: $primary-light;
                }
            }
        }
    }
}
/*
*   END: Home
*/

/*
*   BEGIN: Videos
*/
.youtube-thumb{
    @include background-cover;
}
.videos-thumbs-small-content{
    height: 100%;
}
.videos{
    background-color: #f5f5f5;
    
    h1{
        span{
            background-color: #f5f5f5;
        }
    }
    .row{
        margin-right: 0;
        margin-left: 0;
    }
    .row>.col, 
    .row>[class*="col-"] {
        padding-right: 3px;
        padding-left: 3px;
    }
    @media(max-width: 767px){
        .videos-thumbs-large-content{
            padding-right: 6px !important;
            padding-left: 6px !important;

            .youtube-thumb{
                i{
                    margin-left: -30px !important;
                    margin-top: -30px !important;
        
                    &::before{
                        font-size: 60px !important;
                    }
                }
            }
        }
        .row>.col, 
        .row>[class*="col-"] {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }
    .youtube-thumb{
        display: block;
        i{
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -20px;
            margin-top: -20px;
            opacity: .75;

            &::before{
                position: relative;
                font-size: 40px;
                color: $secondary;
                z-index: 9;
            }
            &::after{
                content: '';
                background-color: $white;
                position: absolute;
                width: 90%;
                height: 90%;
                left: 5%;
                top: 5%;
                @include radius-round;
            }
        }

        &:hover{
            i{
                @include transition;
                transform: scale(1.4);
                opacity: 1;
            }
        }
    }
    .videos-thumbs-large-content{
        .youtube-thumb{
            i{
                margin-left: -30px !important;
                margin-top: -30px !important;
    
                &::before{
                    font-size: 60px !important;
                }
            }
        }
    }
}
/*
*   END: Videos
*/

/*
*   BEGIN: General Post
*/
.additional-information {
    span {
        color: $gray-600;
        font-size: $font-size-small;
        margin: 0 10px;
        @media (max-width: 419px){
            display: block;
        }
        i {
            &::before {
                color: $secondary;
                font-size: $font-size-small;
            }
        }
    }
}

/*
*   END: General Post
*/

/*
*   BEGIN: Post Item
*/
.post-list-item{
    display: block;
    text-decoration: none;
    position: relative;
    height: 100%;
    margin-bottom: 30px;
    @include radius-small;

    .post-list-thumb{
        @include background-cover;
        @include radius-small;
        @include transition;
    }
    .post-list-infos{
        padding: 8px 8px 20px 8px;
        text-align: center;

        @media(min-width: 768px){
            text-align: left;

        }

        .stroke-style-text{
            margin-left: 5px;
        }
        h1{
            font-size: $font-size-xx-large;
            line-height: $font-size-xxx-large;
        }
        .additional-information{
            span{
                margin: 0 10px 0 2px;
                i{
                    &::before{
                        color: $primary-light;
                    }
                }
            } 
        } 
    }

    &:hover{
        text-decoration: none;
        transform: scale(1.04);
        /*
        -webkit-box-shadow: 0px 10px 8px 0px rgba(0,0,0,0.22);
        -moz-box-shadow: 0px 10px 8px 0px rgba(0,0,0,0.22);
        box-shadow: 0px 10px 8px 0px rgba(0,0,0,0.22);
        */
        z-index: 99999;

        .post-list-thumb{
            opacity: .8;
        }
    }
}
/*
*   END: Post Item
*/


/*
*   BEGIN: Post Detail
*/
.post-detail-content{
    .main-figure{
        img{
            @include radius-small;
        }
    }
    .post-detail-text{
        h1,h2,h3,h4,h5,h6{
            margin-top: 35px;
            margin-bottom: 35px;
            color: $primary-light;
        }

        iframe, img{
            max-width: 100%;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        img{
            height: auto !important;
        }

        iframe{
            @media (max-width:500px) {
                height: 200px;
            }
        }
    }
}
/*
*   END: Post Detail
*/
